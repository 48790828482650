import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { device } from '../utils/devices';
import { Layout } from '../layout';
import parseISO from 'date-fns/parseISO';
import { format } from 'date-fns-tz';
import { getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import Seo from '../utils/seo';
import {
  FilterLinks,
  Paginator,
  ListCard,
  TypewriterText,
  AnimationFadeWrapper,
} from '../components';

const PageHeader = styled.div`
  position: sticky;
  inset: 56px auto auto;
  scroll-margin-top: 64px;
  z-index: 1;

  height: calc(50vh - 56px + 56px);
  padding-block-start: calc((50vh - 64px - 2.5rem + 56px) / 2 - 120px);
  padding-block-end: 56px;
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @media ${device.desktop} {
    padding-block-start: calc((50vh - 56px - 2.5rem + 64px) / 2 - 120px);
    height: calc(50vh - 64px + 40px);
    padding-block-end: 64px;
  }
`;

const PageHeaderTitle = styled(motion.h2)`
  font-size: 2.5rem;
  line-height: 3.75rem;
  font-weight: 400;
  letter-spacing: -0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-block-start: 24px;
  @media ${device.tablet} {
    font-size: 3rem;
    line-height: 4.5rem;
    padding-block-start: 32px;
  }
  @media ${device.laptop} {
    font-size: 3.5rem;
    line-height: 5.25rem;
    padding-block-start: 40px;
  }
`;

const ContentContainer = styled.article`
  position: relative;
  z-index: 2;
  background-color: var(--color-background);
  
  display: grid;

  transition-property: grid-columns, grid-rows;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;

  grid-template-columns: 1fr repeat(4, minmax(0, 100%)) 1fr;
  grid-template-rows: 1fr auto;
  gap: calc(24px + 80px) 24px;

  //full content width for grid
  margin-inline:-24px;

  padding-block: 0 24px;
  
  @media ${device.tablet} {
    grid-template-columns: 1fr repeat(8, minmax(0, 100%)) 1fr;
    margin-inline:-32px;
    gap:  32px;
  }
  @media ${device.tabletLarge} {
    margin-inline: -40px;
    gap: 64px 40px;
  }
  @media ${device.laptop} {
    margin-inline:-56px;
    gap: 40px 56px;
  } 
  @media ${device.desktop} {
    grid-template-columns:  1fr repeat(12, minmax(0, 62px)) 1fr;
    margin-inline:-64px;
    gap: 40px 64px;
  } 
  @media ${device.fourk} {
    grid-template-columns:  1fr repeat(12, minmax(0, 72px)) 1fr;
  } 

  > * {
    grid-column: 2 / span 4;

    @media ${device.tablet} {
      grid-column: 2 / span 8;
    }
    @media ${device.desktop} {
      grid-column: 2 / span 12;
    }
  }
}

  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
  @media ${device.fourk} {
  }
`;

interface WordsProps {
  pageContext: {
    name: string;
    total: number;
    limit: number;
    skip: number;
    numPages: number;
    currentPage: number;
    tag?: string;
  };
  data: any;
}

const Words: React.FC<WordsProps> = ({ pageContext, data }) => {
  const { name, total, limit, currentPage, numPages, tag } = pageContext;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;

  const prevPage =
    currentPage - 1 === 1
      ? `/words${tag ? '/' + tag : '/newest'}/`
      : `/words/${tag ? tag + '/' : 'newest/'}${currentPage - 1}/`;
  const nextPage = `/words/${tag ? tag + '/' : 'newest/'}${currentPage + 1}/`;

  const posts = data.allMdx.nodes;

  const tags = data.tagsGroup.group;

  // calculating remaining posts on the last page
  const seenContent = currentPage * limit;
  const remainingContent = total - seenContent;
  const remainingContentNextPage = remainingContent / (numPages - currentPage);

  const capitalizeFirst = (stringToTypeWrite) => {
    return (
      stringToTypeWrite.charAt(0).toUpperCase() + stringToTypeWrite.slice(1)
    );
  };

  return (
    <>
      <Layout withHeader withFooter>
        <PageHeader>
          <PageHeaderTitle
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: 'spring',
              duration: 0.4,
            }}
          >
            {tag ? (
              <TypewriterText firstWords={[capitalizeFirst(tag)]} />
            ) : (
              <TypewriterText firstWords={['Time to read.']} />
            )}
          </PageHeaderTitle>
          <FilterLinks pageContext="words" tags={tags} />
        </PageHeader>
        <AnimationFadeWrapper duration={0.5}>
          <ContentContainer>
            {posts.map((post: any, i: number) => {
              const parsedTime = parseISO(post.frontmatter.publishedOn);
              const formattedDate = format(parsedTime, 'dd. MMMM yyyy');
              const featureImage = getImage(post.frontmatter.featureImage);
              return (
                <ListCard
                  key={post.id}
                  path={`/words/${post.frontmatter.slug}`}
                  featureImage={featureImage}
                  featureImageAlt={post.frontmatter.featureImageAlt}
                  title={post.frontmatter.title}
                  abstract={post.frontmatter.abstract}
                  formattedDate={formattedDate}
                  tags={post.frontmatter.tags}
                />
              );
            })}
          </ContentContainer>

          <Paginator
            contentType={name}
            limit={limit}
            isFirst={isFirst}
            isLast={isLast}
            prevPage={prevPage}
            nextPage={nextPage}
            remainingContentNextPage={remainingContentNextPage}
          />
        </AnimationFadeWrapper>
      </Layout>
    </>
  );
};

export default Words;

interface HeadProps {
  pageContext: any;
  location: any;
}

export const Head: React.FC<HeadProps> = ({ location }) => {
  return (
    <Seo
      title={'Words'}
      description={'Product design blog by Jonathan Esbjug'}
      keywords={['Blog, Words']}
      slug={location.pathname}
    />
  );
};

export const pageQuery: any = graphql`
  query ($skip: Int!, $limit: Int!, $tag: [String]) {
    tagsGroup: allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    allMdx(
      filter: {
        internal: { contentFilePath: { regex: "/posts/" } }
        frontmatter: { tags: { in: $tag } }
      }
      sort: { fields: frontmatter___publishedOn, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        frontmatter {
          slug
          abstract
          title
          publishedOn
          tags
          featureImageAlt
          featureImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, webpOptions: { quality: 30 })
            }
          }
        }
      }
    }
  }
`;
